import React from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "./about.module.scss"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"
import { TM } from "../../components/common/typography/trademark"

const AboutIronCAPAPI = () => (
  <Layout>
    <HeaderBanner
      title={
        <>
          About IronCAP
          <TM /> Toolkits
        </>
      }
    />
    <div className={styles.pageContainer}>
      <Breadcrumb
        firstLink="/ironcap-toolkits"
        firstLinkTitle={
          <>
            IronCAP
            <TM /> Toolkits
          </>
        }
        secondLink="/ironcap-toolkits/about"
        secondLinkTitle={
          <>
            About IronCAP
            <TM /> Toolkits
          </>
        }
      />
      <div className={styles.pageText}>
        <h3>Get your applications ready for the Post-Quantum world</h3>
        <p>
          Our goal is to allow our customers to easily and seamlessly transform
          their products to be safe against cyber attacks from not only the
          classical computers but also against future attacks from quantum
          computers.
        </p>
      </div>
    </div>
    <CTABanner tagline={LearnMoreTagline} CTA="video" CTADetail="how video" />
  </Layout>
)

export default AboutIronCAPAPI
